import revive_payload_client_NLy19An4Dq from "/app/node_modules/.pnpm/nuxt@3.7.4_@types+node@20.8.6_sass@1.69.3_typescript@5.2.2_xml2js@0.6.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_zX8dDdPxl8 from "/app/node_modules/.pnpm/nuxt@3.7.4_@types+node@20.8.6_sass@1.69.3_typescript@5.2.2_xml2js@0.6.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_LIF1i7JQmc from "/app/node_modules/.pnpm/nuxt@3.7.4_@types+node@20.8.6_sass@1.69.3_typescript@5.2.2_xml2js@0.6.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_lg6OqS0gCu from "/app/node_modules/.pnpm/nuxt@3.7.4_@types+node@20.8.6_sass@1.69.3_typescript@5.2.2_xml2js@0.6.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_KN6rDOCkzG from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.2.2_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_3SoJm4EEfT from "/app/node_modules/.pnpm/nuxt@3.7.4_@types+node@20.8.6_sass@1.69.3_typescript@5.2.2_xml2js@0.6.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_Xsg0oaXnxY from "/app/node_modules/.pnpm/nuxt@3.7.4_@types+node@20.8.6_sass@1.69.3_typescript@5.2.2_xml2js@0.6.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import gtm_client_OzmBuHLRIb from "/app/plugins/gtm.client.ts";
import sentry_client_GoGQuZo4Et from "/app/plugins/sentry.client.js";
import vuetify_7h9QAQEssH from "/app/plugins/vuetify.ts";
export default [
  revive_payload_client_NLy19An4Dq,
  unhead_zX8dDdPxl8,
  router_LIF1i7JQmc,
  payload_client_lg6OqS0gCu,
  plugin_vue3_KN6rDOCkzG,
  components_plugin_KR1HBZs4kY,
  prefetch_client_3SoJm4EEfT,
  chunk_reload_client_Xsg0oaXnxY,
  gtm_client_OzmBuHLRIb,
  sentry_client_GoGQuZo4Et,
  vuetify_7h9QAQEssH
]