import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as privacy_45policyOPxqa3IsSYMeta } from "/app/pages/privacy-policy.vue?macro=true";
import { default as indexWctlfRoF06Meta } from "/app/pages/quote/index.vue?macro=true";
import { default as terms_45conditionsRRu4oncQRtMeta } from "/app/pages/terms-conditions.vue?macro=true";
export default [
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyOPxqa3IsSYMeta?.name ?? "privacy-policy",
    path: privacy_45policyOPxqa3IsSYMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyOPxqa3IsSYMeta || {},
    alias: privacy_45policyOPxqa3IsSYMeta?.alias || [],
    redirect: privacy_45policyOPxqa3IsSYMeta?.redirect || undefined,
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: indexWctlfRoF06Meta?.name ?? "quote",
    path: indexWctlfRoF06Meta?.path ?? "/quote",
    meta: indexWctlfRoF06Meta || {},
    alias: indexWctlfRoF06Meta?.alias || [],
    redirect: indexWctlfRoF06Meta?.redirect || undefined,
    component: () => import("/app/pages/quote/index.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsRRu4oncQRtMeta?.name ?? "terms-conditions",
    path: terms_45conditionsRRu4oncQRtMeta?.path ?? "/terms-conditions",
    meta: terms_45conditionsRRu4oncQRtMeta || {},
    alias: terms_45conditionsRRu4oncQRtMeta?.alias || [],
    redirect: terms_45conditionsRRu4oncQRtMeta?.redirect || undefined,
    component: () => import("/app/pages/terms-conditions.vue").then(m => m.default || m)
  }
]